import AdminMainLayout from '../layout/AdminMainLayout'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { CommonTable } from 'components/CommonTable'

export default function AdminReports() {
  const title = "Reports"
  const history = useHistory()

  const reports = [
    {'name': 'Beneficiary Parcels Distribution', 'url': '/admin/reports/beneficiary_parcels'}
  ]

  const onNavigateReport = (url) => {
    history.push(url)
  }

  const columns = [
      {
          field: 'name',
          title: 'Report Name',
          render: (item) => <a onClick={() => onNavigateReport(get(item, "url"))}>{get(item, 'name')}</a>,
      }
  ]

  return(
    <AdminMainLayout
        breadcrumbs={[
            {name: 'admin_home'},
            {name: 'admin_reports', label: 'Reports', url: '/admin/reports'},
        ]}
        is_busy={false}
        is_loading={false}
        title={title}
    >

    <CommonTable
      columns={columns}
      rows={reports}
      dummyData={true}
      item_list={reports}
      can_edit={false}
      canDelete={false}
      bottomPagination={false}
    />

    </AdminMainLayout>
  )
}
