import { ItemList } from 'orm'

class DropdownOptions extends ItemList {
    constructor(dropdown_name) {
        super(`admin_options__${dropdown_name}`)
        this.url = `admin/dropdown_options/${dropdown_name}`
    }

    getEntityKey() {
        return this.url
    }

}

export const adminDonorStates = new DropdownOptions('donor_states')
export const adminBeneficiaryStates = new DropdownOptions('beneficiary_states')
export const adminParcelStates = new DropdownOptions('parcel_states')
export const adminEmployeeRoles = new DropdownOptions('employee_roles')
export const adminAddressTypes = new DropdownOptions('address_types')
export const adminContactRoles = new DropdownOptions('contact_roles')
export const adminDriverStates = new DropdownOptions('driver_states')
export const adminDriverLicenceOptions = new DropdownOptions('drivers_licence_options')
export const adminParcelCompanyTypes = new DropdownOptions('parcel_company_types')
export const adminWarehouseProductFilterQuantityTypes = new DropdownOptions('warehouse_product_filter_quantity_types')
export const adminCompanyReportNames = new DropdownOptions('company_report_names')
export const adminCompanyReportFrequencies = new DropdownOptions('company_report_frequencies')
export const adminNutritionFoodCategories = new DropdownOptions('nutrition_food_categories')

export const adminDaysOfWeek = new DropdownOptions('days_of_week')
export const adminMealtimes = new DropdownOptions('mealtimes')
export const adminFeedingFrequencies = new DropdownOptions('feeding_frequencies')
export const adminRaces = new DropdownOptions('races')
export const adminAgeGroups = new DropdownOptions('age_groups')
export const adminSexes = new DropdownOptions( 'sexes' )
export const adminSeverities = new DropdownOptions( 'severities' )
